.imageContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;

    .mainImage {
        width: 100%;
        height: 100vh;
        display: block;
    }

    .mainImageTextBox {
        position: absolute;
        top: 15vw;
        left: 37vw;
        transform: translate(5%, -50%);
        color: #fcedc3;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;

        .mainImageText1,
        .mainImageText2,
        .mainImageText3,
        .mainImageText4,
        .mainImageText5 {
            opacity: 0;
            transform: translateY(20px);
            animation-duration: 1.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }

        /* 텍스트 애니메이션 */
        .mainImageText1 {
            font-family: 'Shilla_CultureB-Bold';
            color: #11375e;
            font-weight: 700;
            font-size: 2vw;
            animation: slideIn 1.5s ease-out forwards;
            animation-delay: 0.3s;
        }

        .mainImageText2 {
            font-family: 'Shilla_CultureB-Bold';
            margin-top: 1vw;
            font-weight: 700;
            font-size: 2.5vw;
            animation: slideIn 1.5s ease-out forwards;
            animation-delay: 0.6s;
        }

        .mainImageText3 {
            font-family: 'Shilla_CultureB-Bold';
            margin-top: 0.5vw;
            color: #11375e;
            font-weight: 700;
            font-size: 3vw;
            animation: slideIn 1.5s ease-out forwards;
            animation-delay: 0.9s;
            span {
                padding: 0.5vw 1vw;
                background-color: #11375e;
                border-bottom-left-radius: 1vw;
                border-top-right-radius: 1vw;
                color: #FFFFFF;
                font-weight: 1000;
            }
        }

        .mainImageText4 {
            margin-top: 1vw;
            color: #11375e;
            font-weight: 600;
            font-size: 1vw;
            animation: slideIn 1.5s ease-out forwards;
            animation-delay: 1.2s;
        }

        .mainImageText5 {
            font-family: 'Shilla_CultureB-Bold';
            margin-top: 0.5vw;
            color: #fcedc3;
            font-weight: 700;
            font-size: 2.5vw;
            animation: slideIn 1.5s ease-out forwards;
            animation-delay: 1.5s;
        }

        /* 왼쪽에서 슬라이드하며 나타나는 애니메이션 */
        @keyframes slideIn {
            0% {
                opacity: 0;
                transform: translateX(-50px);
            }
            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

/* 보조 이미지 1번과 2번 스타일 */
.sideImages {
    position: absolute;
    top: 6vw;
    right: 4vw;
    display: flex;
    flex-direction: column;
    gap: 2vw; /* 이미지들 간의 기본 간격 설정 */

    /* 개별 이미지의 스타일 */
    .sideImage1 {
        width: 12vw; /* 첫 번째 보조 이미지 크기 조정 */
        height: auto;
        border-radius: 10px;
        margin-top: 2vw; /* 첫 번째 이미지 상단 여백 설정 */
    }

    .sideImage2 {
        width: 12vw; /* 두 번째 보조 이미지 크기 조정 */
        height: auto;
        margin-top: 1vw; /* 두 번째 이미지 상단 여백 설정 */
    }
}


.section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #fffbf5;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 7rem;
        padding-top: 9rem;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1 {
            font-size: 2.5vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 3vw;
            font-size: 1.5vw;
            color: #461900;
        }

        .text3 {
            margin-top: 4vw;
            font-size: 1vw;
            line-height: 2vw;
            color: #a2978a;
        }

        .text4 {
            margin-top: 5vw;
            margin-left: 2vw;
            font-size: 1.2vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .menuBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 100%;
        background-color: #FFFFFF;

        .text {
            margin-top: 1.5vw;
            color: #11375e;
            font-weight: 900;
            font-size: 2vw;

            span {
                padding: 0.5vw 1vw;
                background-color: #11375e;
                border-bottom-left-radius: 1vw;
                border-top-right-radius: 1vw;

                color: #FFFFFF;
                font-weight: 500;
            }
        }

        .btn {
            margin-top: 2vw;
            padding: 0.8vw 1.2vw;
            border: 2px solid #11375e;
            border-radius: 10vw;
            color: #11375e;
            font-size: 1.2vw;
            font-weight: 800;
            text-decoration: none;
        }
    }
}

.section2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100vh;

    background-color: #fffbf5;


    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 6vw 0 0 6vw;
        width: 100%;

        .title {
            color: #11375e;
            font-family: 'HakgyoansimBareonbatangB';
            margin-top: 7vw;
            font-size: 2vw;
            font-weight: 400;
            line-height: 2.5vw;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 2vw;
            }
        }

        .subTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 4px solid #11375e;
                width: 0;
                height: 3.5vw;
            }

            .subText {
                font-size: 1vw;
                line-height: 2vw;
            }

        }
    }

    img {
        margin: 10vw 5vw 0 0;   /* 기본 마진 */
        width: 43vw;            /* 기본 너비 설정 */
        height: auto;           /* 비율 유지하면서 높이 자동 조정 */
    }
    
}

.section3 {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #fff6ea 85%, #c4825f 15%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .textBox {
        margin-top: 14vw;
        margin-left: 5.5vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1,
        .text2,
        .text3,
        .text4,
        .text5 {
            opacity: 0;
            animation: fadeUp 1.8s ease forwards;
        }

        .text1 {
            font-size: 1.8vw;
            animation-delay: 0.3s;
            color: #c98f25;
        }

        .text2 {
            margin-top: 2.5vw;
            line-height: 2.5vw;
            font-size: 1.5vw;
            animation-delay: 0.6s;
            color: #461900;
        }

        .text3 {
            margin-top: 3.8vw;
            font-size: 1.3vw;
            animation-delay: 0.9s;
            color: #a2978a;
        }

        .text4 {
            margin-top: 1.5vw;
            line-height: 2vw;
            font-size: 1.5vw;
            animation-delay: 1.2s;
            color: #381400;
        }

        .text5 {
            margin-top: 1.5vw;
            line-height: 1.8vw;
            font-size: 1vw;
            animation-delay: 1.5s;
            color: #5e5e5e;
        }

        span {
            font-weight: 900;
        }
    }

    img {
        margin-top: 7vw;
        margin-right: 7vw;
        width: 50vw;
    }
}

.section4 {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1vw;

        width: 25%;
        height: 100vh;
        background-color: #fff6ea;

        img {
            width: 7vw;
        }
    }

    .boxTitle {
        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        margin-top: 2vw;
        font-size: 2vw;
        color: #b88c52;
    }

    .boxText1 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1.2vw;
        line-height: 1.5vw;
    }

    .boxText2 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1vw;
        line-height: 1.5vw;
        color: #968978;
    }

    .boxText3 {
        text-decoration: none;
        font-family: 'HakgyoansimBareonbatangB';
        text-align: center;
        margin-top: 2.5vw;
        font-size: 1.2vw;
        color: #fff6ea;
        text-decoration-color: #fff6ea;
    }

    .box:hover {
        background-color: #11375e;
        color: #fff7ea;
    }
}

.section5 {
    display: flex;
    flex-direction: row;

    width: 100vw;
    height: 100%;

    background-color: #fff6ea;

    .imageBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 40vw;
            height: 100vh;
        }

        .text1,
        .text2,
        .text3 {
            width: 40vw;
            position: absolute;
            color: #FFFFFF;
            text-align: center;
        }

        .text1 {
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.5vw;
            font-family: "HakgyoansimBareonbatangB";
        }

        .text2 {
            top: 53%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #d1af73;
            font-size: 2.5vw;
            font-family: "GmarketSansMedium";
        }

        .text3 {
            top: 62%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.2vw;
            font-family: "GmarketSansMedium";
        }
    }

    .textBox {
        margin-top: 8vw;
        margin-left: 4vw;

        width: 50vw;
        

        .text1 {
            font-family: "HakgyoansimBareonbatangB";
            font-size: 2.5vw;
            color: #c98f25;
        }

        .text2 {
            position: relative;
            top: 2vw;
            left: 90%;
            width: 100%;
            text-align: center;
            text-decoration: none;
            font-family: "HakgyoansimBareonbatangB";
            font-size: 1.5vw;
            color: #d1af73;
        }
    }
}

.section6 {
    width: 100vw;
    height: 100%;
}

//모바일 메인 스타일

.mobileMain {

    .imageContainer {
        position: relative; // Make this relative to allow absolute positioning of overlay
        width: 100%;
        height: 100%;

        .mainImage {
            margin-top: 6vw;
            width: 100vw;
            height: 130vw;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%; // Match the container's size
            background-color: rgba(0, 0, 0, 0.5); // 50% opacity
            pointer-events: none;
        }

        .mainImageTextBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            position: absolute;
            top: 50%;
            left: 3%;

            transform: translate(5%, -50%);
            color: #ffe2b0;

            .mainImageTitle {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 5vw;
            }

            .mainImageTextSub {
                margin-top: 1.3vw;
                font-family: 'HakgyoansimBareonbatangA';
                font-weight: 200;
                font-size: 3vw;
            }

            .mainImageLine {
                margin: 2vw 0;
                height: 10vw;
                border-left: 2px solid #ffe2b0;
            }

            .mainImageText {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 4.5vw;
            }
        }

        .mainImageTextBox div {
            display: inline-block;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeUp 1.5s ease forwards;
        }

        .mainImageTextBox div:nth-child(1) {
            animation-delay: 0.3s;
        }

        .mainImageTextBox div:nth-child(2) {
            animation-delay: 0.6s;
        }

        .mainImageTextBox div:nth-child(3) {
            animation-delay: 0.9s;
        }

        .mainImageTextBox div:nth-child(4) {
            animation-delay: 1.2s;
        }

        @keyframes fadeUp {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .container1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        padding: 10vw 0 0 7vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;

        background-color: #fffbf5;

        .text1 {
            font-size: 8vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 8vw;
            font-size: 4vw;
            color: #461900;
        }

        .text3 {
            margin-top: 8vw;
            font-size: 2.5vw;
            font-family: 'HakgyoansimBareonbatangA';
            font-weight: 300;
            line-height: 4vw;
            color: #928677;
        }

        .text4 {
            margin: 15vw 0 10vw 3vw;
            font-size: 3vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

    }

    .container2 {
        width: 100%;
        height: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5vw;

            margin: 30vw 0;

            img{
                width: 50vw;
            }

            .btn {
                margin-top: 0.5vw;
                padding: 1.5vw 2vw;
                border: 2px solid #11375e;
                border-radius: 10vw;
                color: #11375e;
                font-size: 3vw;
                font-weight: 800;
                text-decoration: none;
            }
        }
    }

    .container3 {
        position: relative;
        padding-top: 12vw;
        padding-left: 5.5vw;

        height: 80vw;
        background: linear-gradient(to bottom, #fff6ea 85%, #c4825f 15%);

        .textbox {
            font-family: 'HakgyoansimBareonbatangB';
            font-weight: 700;
            font-size: 3.5vw;

            .text1,
            .text2,
            .text3,
            .text4,
            .text5 {
                opacity: 0;
                animation: fadeUp 1.8s ease forwards;
            }

            .text1 {
                font-size: 4vw;
                animation-delay: 0.3s;
                color: #c98f25;
            }

            .text2 {
                margin-top: 2vw;
                font-size: 5vw;
                line-height: 5.5vw;
                animation-delay: 0.6s;
                color: #461900;
            }

            .text3 {
                margin-top: 5.8vw;
                font-size: 3.5vw;
                animation-delay: 0.9s;
                color: #a2978a;
            }

            .text4 {
                margin-top: 2.5vw;
                font-size: 3vw;
                line-height: 3.5vw;
                animation-delay: 1.2s;
                color: #381400;
            }

            .text5 {
                margin: 3vw 0;
                font-size: 2.2vw;
                line-height: 3.2vw;
                animation-delay: 1.5s;
                color: #5e5e5e;
            }

            span {
                font-weight: 900;
            }
        }

        img {
            position: absolute;
            bottom: 3vw;
            right: 6vw;

            width: 30vw;
        }
    }
}

.container4 {
    width: 100%;
    height: 100%;
    background-color: #fff6ea;

    .text1 {
        padding: 8vw 0 0 6vw;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 5vw;
        color: #c98f25;
    }

    .text2 {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 1.5vw;
        color: #d1af73;

        div {
            @media (max-width: 900px) {
                padding: 6vw 4vw 7vw 0;
                text-align: right;
                font-size: 4vw;
            }
        }
    }
}

.container5 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 90vw;
    }

    .text1,
    .text2,
    .text3 {
        width: 100%;
        position: absolute;
        color: #FFFFFF;
        text-align: center;
    }

    .text1 {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3vw;
        font-family: "HakgyoansimBareonbatangB";
    }

    .text2 {
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #d1af73;
        font-size: 5vw;
        font-family: "GmarketSansMedium";
    }

    .text3 {
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3.5vw;
        font-family: "GmarketSansMedium";
    }
}

.container7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    background-color: #fff8ee;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 4vw 0 0 7vw;
        width: 90%;

        .title {
            color: #11375e;
            font-family: 'HakgyoansimBareonbatangB';
            font-size: 4vw;
            font-weight: 400;
            line-height: 6vw;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 5vw;
            }
        }

        .subTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 1.7px solid #11375e;
                width: 0;
                height: 7vw;
            }

            .subText {
                margin: 0 0 0 1.5vw;
                font-size: 3vw;
                line-height: 4vw;
            }

        }
    }

    img {
        margin: 2vw 0 0 5vw;
        width: 90%;

    }
}
