.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener {
    width: 100vw;
    height: auto;
}

.textBox{
    margin: 4vw 0 0 0;
    text-align: center;
    font-size: 2vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 0.5vw;
        font-weight: 800;
        color: #11375e;
    }
}

.page1Image {
    margin: 4vw 0 4vw 0;
    width: 60%;
    animation: rotateInAndGrow 1.5s ease-out;

    @media (max-width: 900px) {
        width: 90%;
    }

    // 회전하면서 커지는 애니메이션
    @keyframes rotateInAndGrow {
        0% {
            transform: rotateY(-90deg) scale(0.8);  // 90도 회전하면서 작게 시작
            opacity: 0;
        }
        50% {
            transform: rotateY(15deg) scale(1.05);  // 15도 회전하면서 약간 확대
            opacity: 0.8;
        }
        100% {
            transform: rotateY(0deg) scale(1);  // 회전이 끝나고 정상 크기
            opacity: 1;
        }
    }
}
.videoContainer {
    margin: 1rem;
}

/* screenReaderOnly 스타일을 추가하여 시각적으로 숨기지만, 검색 엔진에 SEO를 제공 */
.screenReaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}
