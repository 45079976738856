.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener {
    width: 100vw;
    height: auto;
}

.textBox{
    margin: 3vw 0 0 0;
    text-align: center;
    font-size: 2vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 0.5vw;
        font-weight: 800;
        color: #11375e;
    }
}

.img1 {
    margin-top: 2vw;
    width: 80%;
    animation: zoomInHighlight 1.5s ease-out;

    @media (max-width: 900px) {
        width: 90%;
    }

    // 이미지가 확대되면서 강조되는 애니메이션
    @keyframes zoomInHighlight {
        0% {
            transform: scale(0.9);
            opacity: 0;
            filter: brightness(0.7);  // 어두운 상태로 시작
        }
        50% {
            transform: scale(1.05);
            opacity: 0.8;
            filter: brightness(1.2);  // 밝기가 증가
        }
        100% {
            transform: scale(1);
            opacity: 1;
            filter: brightness(1);  // 정상 밝기
        }
    }
}

.img2 {
    margin: 3vw 0 7vw 0;
    width: 60%;
    animation: slideInInfo 1.5s ease-out;

    @media (max-width: 900px) {
        width: 90%;
    }

    // 부드럽게 왼쪽에서 슬라이드하며 나타나는 애니메이션
    @keyframes slideInInfo {
        0% {
            transform: translateX(-100%) scale(0.8);
            opacity: 0;
        }
        50% {
            transform: translateX(10%) scale(1);
            opacity: 0.6;
        }
        100% {
            transform: translateX(0) scale(1);
            opacity: 1;
        }
    }
}


.tableContainer {
    display: flex;
    flex-direction: row;

    margin-top: 2vw;
    width: 80%;

    @media (max-width: 900px) {
        width: 90%;
    }

    .tableImg{
        margin-bottom: 2vw;
        margin-right: 1vw; 
        @media (max-width: 900px) {
            margin-right: 1vw;
            width: 15vw;
        }
    }

    .projectTable {
        margin-bottom: 2vw;
        width: 100%;
        border-collapse: collapse;

        .label {
            width: 8vw;
            background-color: #fffbf4;
            text-align: center;
            font-size: 1.1vw;
            color: #ad8844;
            
            @media (max-width: 900px) {
                width: 15vw;
                padding: 1.5vw 0;
                font-size: 3vw;
            }
        }

        td {
            border: 1px solid #d3c7bb;
            font-size: 1vw;

            @media (max-width: 900px) {
                font-size: 2.5vw;
                line-height: 4vw;
            }
        }

        .contents {
            padding: 0 2vw 0 1vw;

            @media (max-width: 900px) {
                padding: 0 2vw 0 2vw;
            }
        }
    }
}

.commonBox {
    margin: 1vw 0;
    width: 70%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 80%;
    }

    .notice {

        padding: 0 1vw;
        line-height: 1vw;
        font-size: 0.8w;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0 3vw;
            font-size: 1.5vw;
            line-height: 2vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(6) {
     padding-bottom: 1vw;
    }


    .notice:nth-of-type(7) {
        padding-bottom: 0.1vw;
    }
}


.commonBox1 {
    margin: 1vw 0;
    width: 70%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 80%;
    }

    .notice {

        padding: 0 1vw;
        line-height: 1vw;
        font-size: 0.8w;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0 3vw;
            font-size: 1.5vw;
            line-height: 2vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(6) {
     padding-bottom: 1vw;
    }


    .notice:nth-of-type(7) {
        padding-bottom: 0.1vw;
    }
}

/* screenReaderOnly 스타일을 추가하여 시각적으로 숨기지만, 검색 엔진에 SEO를 제공 */
.screenReaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}