.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener{
    width: 100vw;
    height: auto;
}

.image {
    width: 60rem;
    height: auto;

    @media (max-width: 900px) {
        width: 100vw;
        height: auto;
    }
}


.commonBox {
    margin: 1vw 0;
    width: 70%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 80%;
    }

    .notice {

        padding: 0 1vw;
        line-height: 1vw;
        font-size: 0.8w;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0 3vw;
            font-size: 1.5vw;
            line-height: 2vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 0.5vw;
    }

    .notice:nth-of-type(6) {
     padding-bottom: 1vw;
    }


    .notice:nth-of-type(7) {
        padding-bottom: 0.1vw;
    }
}


/* screenReaderOnly 스타일을 추가하여 시각적으로 숨기지만, 검색 엔진에 SEO를 제공 */
.screenReaderOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
}